import React from 'react';

import {DocumentLink} from '../types/DocumentLink';
import {LaunchPasswordPrompt} from '../utils/PasswordPrompt';

function DocumentCardCmp({
                             title,
                             folder,
                             localAuth,
                             setLocalAuth,
                         }: DocumentLink) {
    const checkPassword = () => {
        setLocalAuth(LaunchPasswordPrompt());
    };

    return (
        <>
            <div className='flex flex-col result sm:max-w-sm base-text-color'>
                <div className='flex flex-col content-between h-full px-6 py-4'>
                    <h2 className='flex mb-2 text-xl font-bold'>{title}</h2>
                    <p className='flex mb-2 text-sm italic dark-blue grow'>
                        To access my documents, click the download link below.
                    </p>

                    <div className='flex justify-center w-full'>
                        {!localAuth ? (
                            <button
                                onClick={checkPassword}
                                className='inline-flex items-center px-6 py-2 font-bold text-white rounded bg-primary hover:bg-secondary'
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='w-6 h-6 mr-4'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z'
                                    />
                                </svg>
                                <span>Locked</span>
                            </button>
                        ) : (
                            <div
                                className='inline-flex items-center px-6 py-2 font-bold text-white rounded bg-primary hover:bg-secondary'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='inline w-6 h-6 mr-4'
                                >
                                    <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3'
                                    />
                                </svg>
                                &nbsp;
                                <a
                                    href={folder}
                                    download
                                    className='text-white hover:no-underline'
                                >
                                    Download
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DocumentCardCmp;
